import Dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import create from 'lodash/create';
import assign from 'lodash/assign';
import omitBy from 'lodash/omitBy';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import size from 'lodash/size';
import every from 'lodash/every';
import some from 'lodash/some';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import filter from 'lodash/filter';
import min from 'lodash/min';
import uniq from 'lodash/uniq';
import debounce from 'lodash/debounce';
import clone from 'lodash/clone';
import Store from './Store.js';
import moment from 'moment';
import BasketService from '../utilities/BasketService.js';
import BasketUtilities from '../utilities/BasketUtilities.js';
import storage from '../utilities/LocalStorage.js';
import { v4 as uuidv4 } from 'uuid';

var courses = {}; // keeps track of courses added to the basket
var students = {};
var prices = []; // price cache, so that upon adding a new course to the basket if we have a price we can use that // TODO: maybe change name/structure?
var customer = {};
var discounts = [];
var lineItems = {}; // keeps track of lineitem data (with pricing) coming back from the api
var isReserving = false;
var isConfirming = false;
var isConfirmed = false;
var isPollingPaymentStatus = false;
var isUpsellSearching = false;
var countries = [];
var reservation, reservationFailureResponse;
var paymentStatus, paymentFailureResponse, paymentIntentId, stripeClientSecret;
var order = undefined;
var termsAndConditions;
var upsells = [];
var options = { };
var basketIdentifier = uuidv4();
var referralSecret = null;
var successRedirectUri;
var paymentStatusEnum = { Created: 1, Success: 2, Fail: 3 };

// when adding courses we go ask the api for the data
var isFetchingCourseData = false;
var courseOptionsData = {}; // if it's an options based course we don't add the course, instead we assign it here so that any components can show it

function reset() {
    courses = {}; // keeps track of courses added to the basket
    students = {}; // keeps track of student data for each item
    prices = [];
    customer = {};
    lineItems = {};
    discounts = [];
    isReserving = false;
    reservation = undefined;
    reservationFailureResponse = undefined;
    isPollingPaymentStatus = false;
    paymentStatus = undefined;
    paymentFailureResponse = undefined;
    paymentIntentId = undefined;
    stripeClientSecret = undefined;
    termsAndConditions = undefined;
    basketIdentifier = uuidv4();
    referralSecret = null;
    order = undefined;
}

function BasketStore() {
    Store.call(this);

    // load items from localstorage
    deserialize();
    updateBasketCookie();
    notifyApi();
}

BasketStore.prototype = create(Store.prototype, {
    'constructor': BasketStore,

    getIsFetchingCourseData: function() {
        return isFetchingCourseData;
    },

    getCourseOptionsData: function (context) {
        if (!courseOptionsData.hasOwnProperty(context)) {
            return false;
        }

        return courseOptionsData[context];
    },

    numberOfItems: function () {
        return size(courses);
    },

    getUpsellOptions: function(lineItem) {
        return options[lineItem.ItemId];
    },

    isUpsellSearching: function() {
        return isUpsellSearching;
    },

    hasUpsell: function (lineItemOption) {
        for (var i = 0; i < upsells.length; i++) {
            if (upsells[i] === lineItemOption) {
                return true;
            }
        }

        return false;
    },

    getCourses: function () {
        return courses;
    },

    getCourseByItemId: function (itemId) {
        if (courses.hasOwnProperty(itemId)) {
            return courses[itemId];
        }

        return false;
    },

    getDiscounts: function() {
        return discounts;
    },

    getBasketTotals: function () {
        var totalExcludingDiscounts = 0;
        var totalIncludingCourseDiscounts = 0;
        var deposit = 0;

        forEach(courses,
            function (lineItem) {
                totalExcludingDiscounts += lineItem.PriceExcludingDiscount;
                totalIncludingCourseDiscounts += lineItem.PriceIncludingDiscount;
                deposit += lineItem.Deposit;
            });
        
        var totalIncludingBasketDiscounts = totalExcludingDiscounts;
        forEach(discounts,
            function (discount) {
                totalIncludingBasketDiscounts -= discount.Discount.Amount;
            });

        return { totalIncludingBasketDiscounts: totalIncludingBasketDiscounts, totalIncludingCourseDiscounts: totalIncludingCourseDiscounts, totalExcludingDiscounts: totalExcludingDiscounts, deposit: deposit };
    },

    //getPrice: function (course, intensity, options) {
    //    var price = getPriceObject(course, intensity, options);
    //    if (!price) {
    //        return false;
    //    }

    //    return price.price;
    //},

    //getStudent: function (itemId) {
    //    if (!students.hasOwnProperty(itemId)) {
    //        students[itemId] = {};
    //    }

    //    return students[itemId];
    //},

    getLineItem: function(courseId, sessionIds, regionId) {
        var key = lineItemKeyMaker(courseId, sessionIds, regionId);
        return lineItems[key];
    },

    getCustomer: function () {
        return clone(customer);
    },

    isReserving: function () {
        return isReserving;
    },

    isConfirming: function () {
        return isConfirming;
    },

    isConfirmed: function () {
        return isConfirmed;
    },

    getReservation: function () {
        return reservation;
    },

    getStripeClientSecret: function () {
        return stripeClientSecret;
    },

    getPaymentIntentId: function () {
        return paymentIntentId;
    },

    getReservationFailureResponse: function () {
        return reservationFailureResponse;
    },

    getCountries: function () {
        return countries;
    },

    getBasketIdentifier: function() {
        return basketIdentifier;
    },

    getReferralSecret: function() {
        return referralSecret;
    },

    getBasketIdentifierAndSecret: function () {
        return {
            basketIdentifier: basketIdentifier,
            secret: referralSecret
        }
    },

    getApplicableTermsAndConditions: function () {
        if (typeof termsAndConditions == "undefined") {
            return false;
        }

        var applicableTermsAndConditions = [];
        forEach(courses, function (courseItem) {
            forEach(termsAndConditions, function (termsAndConditionsDocument) {
                // first we match on the business
                var businessMatch = find(termsAndConditionsDocument.Properties, function(documentProperty) {
                    return courseItem.Region && documentProperty.Key === 'Business' && documentProperty.Value * 1 === courseItem.Region.RegionBusinessId;
                })

                // then we must match on course type
                var courseTypePropertyMatches = find(termsAndConditionsDocument.Properties, function (documentProperty) {
                    return documentProperty.Key === 'CourseType' && documentProperty.Value * 1 === courseItem.Course.CourseType.CourseTypeId;
                });

                if (businessMatch && courseTypePropertyMatches) {
                    // do we match on the dates
                    var effectiveFrom = moment.utc(find(termsAndConditionsDocument.Properties, function (documentProperty) {
                        return documentProperty.Key === 'EffectiveFrom';
                    }).Value, 'DD/MM/YYYY');
                    var effectiveTo = moment.utc(find(termsAndConditionsDocument.Properties, function (documentProperty) {
                        return documentProperty.Key === 'EffectiveTo';
                    }).Value, 'DD/MM/YYYY');

                    if (courseItem.Course.CourseType.AvailabilityModel === 0) {
                        // session based - get the min from date from the options and use that
                        var date = moment.utc(min(courseItem.Options, function (option) {
                            return moment.utc(option.Session.From).unix();
                        }).Session.From);
                    } else if (courseItem.Course.CourseType.AvailabilityModel === 1) {
                        // dates based
                        var date = moment.utc(courseItem.Options[0].StartDate);
                    } else if (courseItem.Course.CourseType.AvailabilityModel === 2) {
                        // not based on dates - check based on todays date
                        var date = moment.utc();
                    }

                    if ((date.isSame(effectiveFrom) || date.isAfter(effectiveFrom)) && (date.isSame(effectiveTo) || date.isBefore(effectiveTo))) {
                        applicableTermsAndConditions.push(termsAndConditionsDocument);
                    }
                }
            });
        });

        return uniq(applicableTermsAndConditions, 'DocumentId');
    },

    getPaymentIntentStatus: function () {
        return paymentStatus;
    },

    getPaymentFailureReason: function () {
        return paymentFailureResponse;
    },

    getOrder: function() {
        return order;
    },

    getRedirectUri: function () {
        return successRedirectUri;
    },

    hasPaymentPollingStarted: function () {
        return paymentStatus !== undefined && paymentStatus !== null;
    },

    isPaymentCreated: function () {
        return isPollingPaymentStatus || paymentStatus === paymentStatusEnum.Created;
    },

    isPaymentSucceeded: function () {
        return paymentStatus === paymentStatusEnum.Success;
    },

    isPaymentFailed: function () {
        return paymentStatus === paymentStatusEnum.Fail;
    },

    notifyApi: notifyApi
});

function lineItemKeyMaker(courseId, sessionIds, regionId) {
    var keyBits = [];
    keyBits.push(courseId + '');
    if (sessionIds) {
        keyBits.push(sessionIds.join(','));
    } else {
        keyBits.push('NOPE');
    }

    if (regionId) {
        keyBits.push(regionId + '');
    } else {
        keyBits.push('NOPE');
    }

    return keyBits.join('|');
}

function isPriceMatch(course, intensity, options, region, price) { // TODO: maybe change method slightly?
    return price.courseId === course.CourseId
            && price.intensityId === intensity.IntensityId
            && ((price.regionId === 0 && region == null) || (region && price.regionId == region.RegionId))
            && (course.CourseType.AvailabilityModel === 2
            || every(options, function (option) {
                return some(price.options, function (myOption) {
                    return (option.Session && myOption.sessionId === option.Session.SessionId)
                        || (option.StartDate && myOption.startDate == option.StartDate);
                });
            }));
}

function getPriceObject(course, intensity, options, region) { // TODO: maybe change method slightly?
    var candidatePrices = filter(prices, function (price) {
        return isPriceMatch(course, intensity, options, region, price);
    });

    if (candidatePrices.length === 0) {
        return false;
    } else if (candidatePrices.length > 1) {
        throw "Argh, found too many matching prices";
    }

    // if it's expired we remove from the prices and return false
    var price = candidatePrices[0];
    if (price.expires < new Date().getTime()) {
        prices = filter(prices, function (otherPrice) {
            return otherPrice !== price;
        });

        return false;
    }

    return price;
}

function serialize() {
    if (!global.localStorage || global.OraWidgets.Options.IsStorageDisabled) {
        return;
    }

    storage.setItem('__ora_widget_courses', JSON.stringify(courses));
    storage.setItem('__ora_widget_prices', JSON.stringify(prices));
    storage.setItem('__ora_widget_customer', JSON.stringify(customer));
    storage.setItem('__ora_widget_discounts', JSON.stringify(discounts));
    storage.setItem('__ora_widget_basket_identifier', JSON.stringify(basketIdentifier));
    storage.setItem('__ora_widget_referral_secret', JSON.stringify(referralSecret));
}

function copyAndDeleteProp(obj, from, to) {
    if (obj.hasOwnProperty(from) && !obj.hasOwnProperty(to)) {
        obj[to] = obj[from];
        delete obj[from];
    }
}

function deserialize() {
    if (!global.localStorage || global.OraWidgets.Options.IsStorageDisabled) {
        return;
    }

    courses = mapValues(JSON.parse(storage.getItem('__ora_widget_courses')) || {},
        function(item) {
            copyAndDeleteProp(item, 'course', 'Course');
            copyAndDeleteProp(item, 'itemId', 'ItemId');
            copyAndDeleteProp(item, 'intensity', 'Intensity');
            copyAndDeleteProp(item, 'options', 'Options');
            copyAndDeleteProp(item, 'selectedRegions', 'SelectedRegions');
            copyAndDeleteProp(item, 'student', 'Student');
            if (item.Student && item.Student.DateOfBirth) {
                // in the WA version dates can be DD/MM/YYYY with any part missing e.g. 05// so we don't want to parse them as moment
                var serialized = item.Student.DateOfBirth;
                item.Student.DateOfBirth = moment.utc(item.Student.DateOfBirth, moment.ISO_8601); 
                if (!item.Student.DateOfBirth.isValid()) {
                    item.Student.DateOfBirth = serialized;
                }
            }

            return item;
        });

    // to prevent issues with old baskets having no course type
    var shouldResetBasket = false;

    forEach(courses, function(course) {
        if (!course.Course.CourseType) {
            shouldResetBasket = true;
            return;
        }
    });

    if (shouldResetBasket) {
        reset();
        serialize();
        return;
    }

    // TODO: maybe change name/structure?
    prices = filter(JSON.parse(storage.getItem('__ora_widget_prices')) || [], function (price) {
        return price.expires > (new Date().getTime());
    });

    customer = JSON.parse(storage.getItem('__ora_widget_customer')) || {};
    discounts = JSON.parse(storage.getItem('__ora_widget_discounts')) || [];
    basketIdentifier = JSON.parse(storage.getItem('__ora_widget_basket_identifier'));
    if (!basketIdentifier) { 
        // generate new one and save in local storage for later
        basketIdentifier = uuidv4();
        storage.setItem('__ora_widget_basket_identifier', JSON.stringify(basketIdentifier));
    }
    
    referralSecret = JSON.parse(storage.getItem('__ora_widget_referral_secret')) || null;
}

function notifyApi() {
    if (!OraWidgets.Options.IsApiStorageDisabled) {
        BasketService.saveBasket(customer, courses, OraWidgets.Options.BasketId, basketIdentifier).then(function (data) {
            if (data != undefined && data != null) {
                basketIdentifier = data.BasketIdentifier;
                referralSecret = data.ReferralSecret;
            }
        });
    }
}

var debouncedNotifyApi = debounce(notifyApi, 1000);

var searchId = 0;
function getDiscounts() {
    searchId++;
    BasketService.getDiscounts(customer, courses, basketIdentifier)
        .done((function createResponseFunc(mySearchId) {
            return function (response) {
                if (mySearchId !== searchId) {
                    return;
                }

                if (response != undefined && response != null) {
                    discounts = response;
                    basketStore.emitChange();
                    setTimeout(serialize, 1); // record in localStorage
                }
            };
        })(searchId));
}

var debouncedGetDiscounts = debounce(getDiscounts, 1000, { leading: true });

var basketStore = new BasketStore();
basketStore.dispatchToken = Dispatcher.register(function (payload) {
    switch (payload.type) {
        case ActionTypes.GET_LINEITEM_DATA_SUCCESS:
            var key = lineItemKeyMaker(payload.courseId, payload.sessionIds, payload.regionId);
            lineItems[key] = payload.lineItem;
            break;

        case ActionTypes.GET_LINEITEM_STARTED:
            isFetchingCourseData = true;
            break;

        case ActionTypes.GET_LINEITEM_OPTIONS_SUCCESS:
            isFetchingCourseData = false;
            courseOptionsData[payload.context] = payload;
            break;

        case ActionTypes.ADD_COURSE:
            isFetchingCourseData = false;
            var newItemId = payload.itemId ? payload.itemId : getNextItemId() + '_';
            var newCourse = {};

            newCourse[newItemId] = {
                ItemId: newItemId,
                ParentItemId: payload.parentItemId,
                Course: payload.lineItem.Course,
                Intensity: payload.lineItem.Intensity,
                Options: payload.lineItem.Options,
                Region: payload.lineItem.Region,
                Student: payload.student || {},
                PriceExcludingDiscount: payload.lineItem.PriceExcludingDiscount,
                PriceIncludingDiscount: payload.lineItem.PriceIncludingDiscount,
                Deposit: payload.lineItem.Deposit,
                TimeZone: payload.lineItem.TimeZone
            };
            
            courses = assign({}, courses, newCourse);
            delete courseOptionsData[payload.context];
            websiteGetDiscounts();
            break;

        case ActionTypes.REMOVE_COURSE:
            if (courses.hasOwnProperty(payload.itemId)) {
                courses = omitBy(courses,
                    function(course, id) {
                        return id == payload.itemId || course.ParentItemId == payload.itemId;
                    });
            }

            websiteGetDiscounts();
            break;

        case ActionTypes.REPLACE_COURSE:
            if (courses.hasOwnProperty(payload.itemId)) {
                courses[payload.itemId] = {
                    ItemId: payload.itemId,
                    Course: payload.lineItem.Course,
                    Intensity: payload.lineItem.Intensity,
                    Options: payload.lineItem.Options,
                    Region: payload.lineItem.Region,
                    Student: payload.student || {},
                    PriceExcludingDiscount: payload.lineItem.PriceExcludingDiscount,
                    PriceIncludingDiscount: payload.lineItem.PriceIncludingDiscount,
                    Deposit: payload.lineItem.Deposit,
                    TimeZone: payload.lineItem.TimeZone
                };
            }

            break;

        case ActionTypes.UPDATE_STUDENT:
            // update the student within the course
            courses = mapValues(courses, function (course, itemId) {
                if (itemId === payload.itemId
                    || course.ParentItemId === payload.itemId) {
                    var propChange = {};
                    propChange[payload.property] = payload.value;
                    var update = assign({}, course.Student, propChange);
                    return assign({}, course, { Student: update });
                }

                return course;
            });

            // update the students within the upsell options
            if (options && options[payload.itemId] && options[payload.itemId].Options) {
                forEach(options[payload.itemId].Options,
                    function(lineItemOption) {
                        forEach(lineItemOption.Options,
                            function(option) {
                                if (option.LineItem.ItemId === payload.itemId) {
                                    option.LineItem.Student[payload.property] = payload.value;
                                }
                            });
                    });
            }

            break;

        case ActionTypes.CHOOSE_STUDENT:
            var currentStudent = assign({}, courses[payload.itemId].Student);

            // update the student for the course
            courses = mapValues(courses, function (course, itemId) {
                if (itemId === payload.itemId
                    || BasketUtilities.studentEquals(course.Student, currentStudent)) {
                    var update = assign({}, course.Student, payload.person);
                    return assign({}, course, { Student: update });
                }

                return course;
            });

            // update the students within the upsell options
            if (options && options[payload.itemId] && options[payload.itemId].Options) {
                forEach(options[payload.itemId].Options,
                    function (lineItemOption) {
                        forEach(lineItemOption.Options,
                            function (option) {
                                if (option.LineItem.ItemId === payload.itemId) {
                                    option.LineItem.Student = payload.person;
                                }
                            });
                    });
            }

            websiteGetDiscounts();
            break;

        case ActionTypes.UPDATE_CUSTOMER:
            customer[payload.property] = payload.value;

            break;

        case ActionTypes.CHOOSE_CUSTOMER:
            customer = assign({}, customer, payload.person);
            customer.ConfirmEmail = customer.Email;

            break;

        case ActionTypes.MAKE_RESERVATION_STARTED:
            reservationFailureResponse = undefined;
            reservation = undefined;
            isReserving = true;

            isPollingPaymentStatus = false;
            paymentStatus = undefined;
            paymentFailureResponse = undefined;
            order = undefined;
            break;

        case ActionTypes.MAKE_RESERVATION_SUCCESS:
            reservation = payload.reservation;
            paymentIntentId = reservation.PaymentIntentId;
            isReserving = false;
            if (!payload.makePayment) {
                alert('The reservation was successfully made');
                reset();
            }
            break;

        case ActionTypes.MAKE_RESERVATION_FAILURE:
            reservationFailureResponse = payload.response;
            isReserving = false;
            break;

        case ActionTypes.GET_ALL_COUNTRIES:
            countries = payload.countries;
            break;
        
        case ActionTypes.GET_ALL_TERMS_AND_CONDITIONS:
            termsAndConditions = payload.termsAndConditions;
            break;

        case ActionTypes.MAKE_BOOKING_STARTED:
            isConfirming = true;
            break;

        case ActionTypes.MAKE_BOOKING_SUCCESS:
            isConfirmed = true;
            isConfirming = false;
            break;

        case ActionTypes.CLEAR_BASKET:
            // reset everything
            reset();
            websiteGetDiscounts();
            break;

        case ActionTypes.LOAD_BASKET:
            reset();
            customer = payload.customer;
            if (payload.basketIdentifier != undefined && payload.basketIdentifier != null) {
                basketIdentifier = payload.basketIdentifier;
            }

            websiteGetDiscounts();
            break;

        case ActionTypes.ADDED_UPSELL:
            upsells.push(payload.lineItemOption);
            break;

        case ActionTypes.SEND_UPSELL_REQUEST_BEGIN:
            isUpsellSearching = true;
            break;

        case ActionTypes.SEND_UPSELL_REQUEST_FAILURE:
            isUpsellSearching = false;
            options = { "dummy": { Options: [] }};
            break;

        case ActionTypes.SEND_UPSELL_REQUEST_SUCCESS:
            isUpsellSearching = false;

            // we go through and replace the current line items in here with our versions from above
            // this keeps the data structure the same but also ensures we have prices!
            if (payload.options && payload.options.data && payload.options.data.Options) {
                forEach(payload.options.data.Options, function (lineItemOption) {
                    forEach(lineItemOption.Options, function (option) {
                        option.LineItem = basketStore.getCourseByItemId(option.LineItem.Key);
                    });
                });
            }

            options[payload.options.itemId] = payload.options.data;
            break;
        
        case ActionTypes.CREATE_PAYMENT_INTENT_SUCCESS:
            paymentIntentId = payload.paymentIntentId;
            break;

        case ActionTypes.POLL_PAYMENT_START:
            isPollingPaymentStatus = true;
            paymentStatus = paymentStatusEnum.Created;
            break;
        
        case ActionTypes.POLL_PAYMENT_FINISHED:
            isPollingPaymentStatus = false;
            paymentStatus = payload.paymentStatus;
            paymentFailureResponse = payload.paymentFailureReason;
            successRedirectUri = payload.redirectUri;
            order = payload.order;
            break;

        case ActionTypes.DISCOUNTS_FETCHED:
            discounts = payload.discounts;
            break;
        
        default:
            return;
    }

    basketStore.emitChange();
    setTimeout(serialize, 1); // record in localStorage
    updateBasketCookie();
    if (!global.OraWidgets.IsWebsite) { // the website uses apply wizard which provides student info and courses all at once. This makes it simpler to calculate discounts so we don't send this all time
        debouncedGetDiscounts();
    }
    debouncedNotifyApi();
});

function websiteGetDiscounts() {
    if (global.OraWidgets.IsWebsite) { 
        debouncedGetDiscounts();
    }
}

function updateBasketCookie() {
    // we set the cookie for 30 days as this checkout code does not exist 
    // on the rest of the site so a session based cookie would fail when someone
    // re-visited the site and they did have a basket but hadn't hit the checkout/apply page yet
    if (!OraWidgets.Options.IsApiStorageDisabled) {
        document.cookie = 'hasBasket=' + (isEmpty(courses) ? 'false' : 'true') + ';max-age=2592000;path=/';
    }
}

// next item id function 
// we use milliseconds as this should be unique across tabs/windows for the same computer
function getNextItemId() {
    return new Date().getTime();
}

// listen to storage events from other tabs and update if necessary
if (window.addEventListener) {
    window.addEventListener('storage', updateBasketFromStorage, false);
} else {
    window.attachEvent('onstorage', updateBasketFromStorage);
}

function updateBasketFromStorage() {
    deserialize();
    basketStore.emitChange();
}

export default basketStore;